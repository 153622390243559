<template>
    <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="checkout-company-form">
        <div class="listing_loader" v-show="companySettingLoader"><quote-loader /></div>
        <div class="content_wpr mb-0" v-show="!companySettingLoader">
            <div class="section_content w-100">
                <!-- <div class="section_header">
                    <h2>Company Settings</h2>
                </div> -->
                <div class="color_container mt-1">
                    <div class="setting_wpr mb-5">
                        <h3 class="sub_header">Company Logo</h3>
                        <image-library v-model="form.logo" image-type="public-logo" upload-text="logo" />
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Company Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: mycompany" rules="required" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">FROM name on your outgoing emails</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.email_from }">
                                    <Field autocomplete="off" type="text" name="email_from" v-model="form.email_from" placeholder="ex: mycompany" rules="required" />
                                </div>
                                <ErrorMessage name="email_from" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Subdomain</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.subdomain }">
                                    <Field autocomplete="off" type="text" name="subdomain" v-model="form.subdomain" placeholder="ex: mycompany" rules="required|alpha_num|lowercase|max:50" />
                                    <div class="basic_slug">{{ env.VUE_APP_CHECKOUT_URL }}</div>
                                </div>
                                <ErrorMessage name="subdomain" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Default Country</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.default_country }">
                                    <Field autocomplete="off" name="default_country" v-model="form.default_country" :class="{ 'has-error': errors.default_country }" lable="country">
                                        <multiselect
                                            v-model="form.default_country"
                                            :options="countries"
                                            value-prop="code"
                                            label="country"
                                            :searchable="true"
                                            placeholder="Select default country"
                                            autocomplete="nofill"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage  name="default_country" class="text-danger" />
                            </div>
                        </div>
                        <p class="note">Select a country to be displayed as default on your checkout pages. Your customers can always select a different country</p>
                        <h3 class="sub_header border-bottom pb-2 pt-4 mt-5">Marketplace Address</h3>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Street</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.street }">
                                    <Field v-model="form.street" name="street">
                                        <textarea cols="30" rows="10" v-model="form.street" placeholder="ex: 123 Anywhere Street."></textarea>
                                    </Field>
                                </div>
                                <ErrorMessage name="street" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">APT#</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.apartment }">
                                    <Field autocomplete="off" type="text" name="apartment" v-model="form.apartment" placeholder="ex: Skyline" />
                                </div>
                                <ErrorMessage name="apartment" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Country</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.country }">
                                    <Field autocomplete="off" name="country" v-model="form.country" :class="{ 'has-error': errors.country }" lable="country">
                                        <multiselect
                                            v-model="form.country"
                                            :options="countries"
                                            value-prop="code"
                                            label="country"
                                            :searchable="true"
                                            placeholder="Select country"
                                            autocomplete="nofill"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage  name="country" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">City</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.city }">
                                    <Field autocomplete="off" type="text" name="city" v-model="form.city" placeholder="ex: Lemont" />
                                </div>
                                <ErrorMessage name="city" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Region</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.state }">
                                    <Field autocomplete="off" type="text" name="state" v-model="form.state" placeholder="ex: Pennsylvania" />
                                </div>
                                <ErrorMessage name="state" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Postal Code</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.zipcode }">
                                    <Field autocomplete="off" type="text" name="zipcode" v-model="form.zipcode" placeholder="ex: 16851" />
                                </div>
                                <ErrorMessage name="zipcode" class="text-danger" />
                            </div>
                        </div>
                        <p class="note">Your marketplace address is required for sales tax calculations and invoicing</p>
                        <label for="has_address_receipt" class="switch_option capsule_btn mt-5 mb-4">
                            <h5>Show address of receipts? <span>For UE/VAT tax compliance, your address should appear on your receipts</span></h5>
                            <input type="checkbox" id="has_address_receipt" v-model="form.has_address_receipt" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Tax Type</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.tax_type }">
                                    <Field autocomplete="off" name="tax_type" v-model="form.tax_type" :class="{ 'has-error': errors.tax_type }" lable="tax type">
                                        <multiselect
                                            v-model="form.tax_type"
                                            :options="['VAT', 'HST']"
                                            :searchable="true"
                                            placeholder="Select tax type"
                                            autocomplete="nofill"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage  name="tax_type" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">{{ form.tax_type }} ID (optional)</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.vat_id }">
                                    <Field autocomplete="off" type="text" name="vat_id" v-model="form.vat_id" placeholder="ex: VAT ID goeas here.." />
                                </div>
                                <ErrorMessage name="vat_id" class="text-danger" />
                            </div>
                        </div>
                        <p class="note">This will appear on your invoices when applicable. Thrive Coach does not use this information for filling</p>
                        <div class="form_grp mb-2 mt-5">
                            <div class="group_item">
                                <label class="input_label">Timezone</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.timezone }">
                                    <Field autocomplete="off" name="timezone" v-model="form.timezone" :class="{ 'has-error': errors.timezone }">
                                        <multiselect
                                            v-model="form.timezone"
                                            :options="timezones"
                                            valueProp="code"
                                            label="timezone"
                                            :searchable="true"
                                            placeholder="Select timezone"
                                            autocomplete="nofill"
                                            :no-options-text="!form.country || !form.country.length ? 'Plese select country first' : 'The list is empty'"
                                            ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage  name="timezone" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Support Contact</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.support_address }">
                                    <Field autocomplete="off" type="text" name="support_address" v-model="form.support_address" placeholder="ex: help@yourcompany.com" />
                                </div>
                                <ErrorMessage name="support_address" class="text-danger" />
                            </div>
                        </div>
                        <!-- <div class="form_grp mb-2 mt-2">
                            <div class="group_item">
                                <label class="input_label">Currency</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.currency }">
                                    <Field autocomplete="off" name="currency" v-model="form.currency" :class="{ 'has-error': errors.currency }">
                                        <multiselect
                                            v-model="form.currency"
                                            :options="currencies"
                                            valueProp="code"
                                            label="currency"
                                            :searchable="true"
                                            autocomplete="nofill"
                                            :disabled="hasCurrency ? true : false"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="currency" class="text-danger" />
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <button type="button" class="preview_btn" @click="$parent.$parent.preview = true">Preview</button> -->
        <div class="action_wpr mb-5" v-show="!companySettingLoader">
            <button type="button" class="btn cancel_btn" @click="$parent.$parent.closeModal()">Cancel</button>
            <button :disabled="loader" type="submit" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Next' }}</button>
        </div>
    </Form>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapMutations } from 'vuex'

    export default {
        name: 'Checkout Company Setting',

        data () {
            return {
                form: {
                    logo: '',
                    name: '',
                    email_from: '',
                    subdomain: '',
                    default_country: '',
                    street: '',
                    apartment: '',
                    country: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    has_address_receipt: 0,
                    vat_id: '',
                    timezone: '',
                    support_address: '',
                    currency: '',
                    tax_type: 'VAT',
                },
                env: {},
                hasCurrency: 0,
            }
        },

        props: {
            companyHandler: Function
        },

        watch: {
            'form.default_country' (country) {
                const vm = this;

                if (country) {
                    vm.getTimezonesByCountry({ country });
                } else {
                    vm.form.timezone = [];
                    vm.resetTimezoneByCountry([]);
                }
            },

            companySettings (settings) {
                const vm = this;

                vm.resetForm();
            }
        },

        components:{
            Form,
            Field,
            ErrorMessage,
        },

        computed: mapState({
            timezones: state => state.commonModule.timezonesByCountry,
            countries: state => state.commonModule.countries,
            currencies: state => state.commonModule.currencies,
            companySettings: state => state.checkoutModule.companySettings,
            companySettingLoader: state => state.checkoutModule.companySettingLoader,
            loader: state => state.checkoutModule.updateCompanySettingLoader,
        }),

        mounted () {
            const vm = this;

            if (!vm.countries.length) {
                vm.getCountries();
            }

            vm.getCompanySettings();

            vm.env = process.env;
        },

        methods: {
            ...mapActions({
                getTimezonesByCountry: 'commonModule/getTimezonesByCountry',
                getCountries: 'commonModule/getCountries',
                getCompanySettings: 'checkoutModule/getCompanySettings',
                updateCompanySettings: 'checkoutModule/updateCompanySettings'
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            resetForm () {
                const vm = this;

                vm.form = {
                    id: vm.companySettings.id ? vm.companySettings.id : '',
                    name: vm.companySettings.name ? vm.companySettings.name : '',
                    email_from: vm.companySettings.email_from ? vm.companySettings.email_from : '',
                    logo: vm.companySettings.logo ? vm.companySettings.logo : '',
                    subdomain: vm.companySettings.subdomain ? vm.companySettings.subdomain : '',
                    default_country: vm.companySettings.default_country ? vm.companySettings.default_country : '',
                    street: vm.companySettings.street ? vm.companySettings.street : '',
                    apartment: vm.companySettings.apartment ? vm.companySettings.apartment : '',
                    country: vm.companySettings.country ? vm.companySettings.country : '',
                    city: vm.companySettings.city ? vm.companySettings.city : '',
                    state: vm.companySettings.state ? vm.companySettings.state : '',
                    zipcode: vm.companySettings.zipcode ? vm.companySettings.zipcode : '',
                    has_address_receipt: vm.companySettings.has_address_receipt ? vm.companySettings.has_address_receipt : 0,
                    vat_id: vm.companySettings.vat_id ? vm.companySettings.vat_id : '',
                    timezone: vm.companySettings.timezone ? vm.companySettings.timezone : '',
                    support_address: vm.companySettings.support_address ? vm.companySettings.support_address : '',
                    currency: vm.companySettings.currency ? vm.companySettings.currency : 'USD',
                    tax_type: vm.companySettings.tax_type ? vm.companySettings.tax_type : 'VAT',
                };

                vm.hasCurrency = vm.companySettings.currency ? 1 : 0;
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;

                vm.updateCompanySettings(vm.form).then((result) => {
                    if (result) {
                        vm.companyHandler();
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .listing_loader{
        width: 100%;
        margin-top: 30px;
    }
    
    .form_grp .field_wpr {
        display: flex;
    }

    .form_grp .field_wpr .basic_slug {
        height: 50px;
        padding: 0 15px;
        font-size: 13px;
        line-height: 17px;
        font-weight: 300;
        color: #999;
        display: flex;
        align-items: center;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
    }

    p.note {
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    .checkout-company-form {
        display: flex;
        flex-direction: column;
        height: inherit;
        width: 100%;
    }
    .checkout-company-form .color_container{
        background: #fff;
        border: 1px solid #f5f5f5;
    }

    .global_setting .preview_content  {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }


    .preview_content.show  {
        margin-right: 0;
    }

    .preview_content .close_btn  {
        position: absolute;
        left: -35px;
        top: 5px;
        font-size: 14px;
    }

    .preview_btn  {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .cell  {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .preview_content .cell:after,
    .preview_content .cell:before  {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview_content .cell:before  {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .preview_content .cell:after  {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .preview_content .cell .content_area  {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview  {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        border-radius: 10px;
    }

    .cell .sms_preview p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview:after, .cell .sms_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }


    .cell .msg_preview h2  {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }


    .cell .msg_preview :deep(p)  {
        font-size: 11px !important;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }


    .cell .msg_preview p span  {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    p.note {
        padding: 10px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    p.note :deep(p) {
        margin: 10px 0;
    }
</style>
